import { useCallback } from 'react';
import { createCampus, deleteCampus, updateCampus } from '../api';

interface UseActionsParams {
  createCampus: any;
  updateCampus: { campusId: number; data: any };
}
type ActionType =
  | 'create'
  | 'update'
  | 'delete'
  | 'Sede creada con éxito'
  | 'Sede modificada con éxito'
  | 'Sede eliminada con éxito';

export default function useActions(props: {
  onSuccess: (action: ActionType) => void;
  onError: (action: ActionType, message?: string) => void;
}) {
  const createCampusAction = useCallback(
    async (data: UseActionsParams['createCampus']) => {
      const result = await createCampus(data);
      if (!result.error) {
        return props.onSuccess('Sede creada con éxito');
      }
      props.onError('create', result.error.data.message);
    },
    [props],
  );

  const updateCampusAction = useCallback(
    async (params: UseActionsParams['updateCampus']) => {
      const result = await updateCampus(params.campusId, params.data);
      if (!result.error) {
        return props.onSuccess('Sede modificada con éxito');
      }
      props.onError('update', result.error.data);
    },
    [props],
  );

  const deleteCampusAction = useCallback(
    async (campusId: number) => {
      const result = await deleteCampus(campusId);
      if (!result.error) {
        return props.onSuccess('Sede eliminada con éxito');
      }
      props.onError('delete', result.error.data.message);
    },
    [props],
  );

  return {
    createCampus: createCampusAction,
    updateCampus: updateCampusAction,
    deleteCampus: deleteCampusAction,
  };
}
